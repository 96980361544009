<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat dense>
          <v-toolbar-title>Web 100</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <div>
            <router-view />
          </div>
        </v-card-text>
      </v-card>
    </v-container>
</template>
<script>
export default {
  watch: {
    "$route.params.rule_id": {
      handler: "setId",
      immediate: true
    }
  },
  methods: {
    setId(id) {
      this.id = id;
    }
  }
};
</script>